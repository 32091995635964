// extracted by mini-css-extract-plugin
export var attachment = "ReferalPageForm-module--attachment--14ee5";
export var attachmentLabel = "ReferalPageForm-module--attachmentLabel--901a5";
export var errorTxt = "ReferalPageForm-module--errorTxt--2647e";
export var fieldColor = "ReferalPageForm-module--fieldColor--b7181";
export var formControl = "ReferalPageForm-module--form-control--998fc";
export var formInnerReferalPage = "ReferalPageForm-module--formInnerReferalPage--2827d";
export var hr = "ReferalPageForm-module--hr--fd8c6";
export var newlinks = "ReferalPageForm-module--newlinks--53c21";
export var query = "ReferalPageForm-module--query--85e88";
export var salesFormCheckBoxSize = "ReferalPageForm-module--salesFormCheckBoxSize--1cf1d";
export var salesFormRowHeight = "ReferalPageForm-module--salesFormRowHeight--a143e";