import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import FormModal from "./FormModal"
import * as styles from "./Commission.module.scss"

const Commission = ({ strapiData }) => {
  const [showModal, setShowModal] = useState(false)

  const [modalData, setModalData] = useState(null)
  const handleModalShow = title => {
    const referralPageTitle = `Referral Page - General Referrals`
    setModalData(referralPageTitle)
    setShowModal(true)
  }
  const handleModalClose = () => setShowModal(false)
  return (
    <div className={`${styles.commissionServices}`}>
      <Container className={styles.con}>
        <div className={styles.Ready}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={`${styles.bannerCommissionBtn}`}>
            {strapiData?.buttons[0] && (
              <button
                onClick={() => handleModalShow(strapiData?.title)}
                className={styles.btn_white2_border_referal_btn}
              >
                {strapiData?.buttons[0]?.title}
              </button>
            )}
          </div>
          <FormModal
            showModal={showModal}
            handleClose={handleModalClose}
            modalData={modalData}
          />
        </div>
      </Container>
    </div>
  )
}

export default Commission
