import React from "react"
import * as styles from "./Process.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Process = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.process}>
        <Container>
          <h2
            className={styles.ProcessHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.ProcessSubtitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` gap-30 ${styles.processWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col
                  lg={4}
                  md={6}
                  xs={12}
                  sm={12}
                  className={styles.Card}
                  key={i}
                >
                  <div className={styles.processData}>
                    <span className={styles.stepProcess}>Step {+(i + 1)}</span>
                    <h3>{e?.title}</h3>
                    <p
                      className={styles.processDes}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Process
