import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import { encodeToFormData, isEmail } from "../../utils"
import * as styles from "./ReferalPageForm.module.scss"
import "./ReferalPageForm.scss"

const FaqForm = ({ pageName = "unknown" }) => {
  const [status, setStatus] = useState("init")
  const [isValidPhoneFormat, setIsValidPhoneFormat] = useState(true)
  const [isChecked, setIsChecked] = useState(false)

  const initValues = {
    fullName: "",
    email: "",
    linkedInLink: "",
    page: pageName,
    phoneContact: "",
  }
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  //   const handleChange = e => {
  //     const { type, name } = e.target
  //     if (type === "checkbox") values[name] = e.target.checked
  //     else if (type === "file") values[name] = e.target.files[0]
  //     else values[name] = e.target.value
  //     setValues({ ...values })

  //     if (errors.hasOwnProperty(name)) {
  //       delete errors[name]
  //       setErrors(errors)
  //     }
  //   }

  const handleChange = e => {
    const { type, name } = e.target
    if (type === "checkbox") {
      const isChecked = e.target.checked
      setValues({ ...values, [name]: isChecked })
      setIsChecked(isChecked)
    } else if (type === "file") {
      values[name] = e.target.files[0]
      setValues({ ...values })
    } else {
      values[name] = e.target.value
      setValues({ ...values })
    }

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "fullName":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "email":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        case "linkedInLink":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "phoneContact":
          // if (value?.indexOf("0") === 0)
          //   newErrors[key] = "Not Start number with zero"

          break
        default:
          break
      }
    }
    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(values)
    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors)
      return
    }
    if (!isValidPhoneFormat) {
      return
    }
    setStatus("submitting")
    let local_data = {
      ...values,
      // phone: `(${values.countryName.toUpperCase()}) +${values.countryCode} ${
      //   values.phoneNumber
      // }`,
    }
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9029`
    const formData = encodeToFormData(local_data)

    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })

      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        setValues(initValues)
        setStatus("success")
        setTimeout(() => {
          setStatus("init")
        }, 2000)
      } else {
        setStatus("failed")
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <React.Fragment>
      <div className={`${styles.formInnerReferalPage} ReferalPageForm`}>
        <h3>Join Our Program Today!</h3>
        <Form name="Contact Sales Form" method="POST" onSubmit={handleSubmit}>
          <Form.Group controlId="fullName">
            <Form.Label className="font-weight-normal px-1">
              Full Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Name*"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              isInvalid={errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="font-weight-normal px-1">
              Email Address
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email*"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label className="font-weight-normal px-1">
              Linkedin Profile Link
            </Form.Label>
            <Form.Control
              type="url"
              placeholder="LinkedIn Profile Link*"
              name="linkedInLink"
              value={values.linkedInLink}
              onChange={handleChange}
              isInvalid={errors.linkedInLink}
            />
            <Form.Control.Feedback type="invalid">
              {errors.linkedInLink}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="checkbox">
            <Form.Check
              type="checkbox"
              label="I have read and understood the terms & conditions."
              name="checkbox"
              checked={isChecked}
              onChange={handleChange}
              isInvalid={errors.checkbox}
            />
            <Form.Control.Feedback type="invalid">
              {errors.checkbox}
            </Form.Control.Feedback>
          </Form.Group>

          <div className=" submit-button-cta">
            <Button
              variant="primary"
              type="submit"
              aria-label="Send"
              disabled={status !== "submitting" ? false : true}
              className={styles.query}
              id="sale_section_contact_us_form_gt"
            >
              {status === "submitting" ? (
                <React.Fragment>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span> Sending</span>
                </React.Fragment>
              ) : (
                "Get Started"
              )}
            </Button>
          </div>
          <div>
            {status === "success" ? (
              <p
                className=" m-0 mt-2 w-100 text-left "
                style={{ fontSize: "12px,", color: "#0FC30F" }}
              >
                Submit Successfully
              </p>
            ) : status === "failed" ? (
              <p
                className="text-danger m-0 mt-2 w-100 text-left "
                style={{ fontSize: "12px" }}
              >
                Somthing Went Wrong
              </p>
            ) : (
              <p className="m-0 mt-2 w-100 text-left"></p>
            )}
          </div>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default FaqForm
