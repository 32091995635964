// extracted by mini-css-extract-plugin
export var Ready = "Commission-module--Ready--556b1";
export var bannerCommissionBtn = "Commission-module--bannerCommissionBtn--20b39";
export var btn_white2_border_referal_btn = "Commission-module--btn_white2_border_referal_btn--a18c8";
export var card = "Commission-module--card--6da8c";
export var cardWrapper = "Commission-module--cardWrapper--83fdb";
export var commissionServices = "Commission-module--commissionServices--e9a03";
export var con = "Commission-module--con--39c46";
export var description = "Commission-module--description--5d00c";
export var description2 = "Commission-module--description2--53559";
export var heading = "Commission-module--heading--3af18";