import React, { useState } from "react"
import * as styles from "./Banner.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FormModal from "./FormModal"
const Banner = ({ strapiData }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const handleModalShow = title => {
    const referralPageTitle = `Referral Page - ${title}`
    setModalData(referralPageTitle)
    setShowModal(true)
  }
  const handleModalClose = () => setShowModal(false)
  return (
    <React.Fragment>
      <div className={styles.bannerReferalPage}>
        <Container>
          <p
            className={styles.referalSubtitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <h1
            className={styles.bannerHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.referalSubtitle2}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />
          <Row className={` gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, index) => (
                <Col
                  lg={4}
                  md={5}
                  xs={10}
                  sm={10}
                  className={styles.Card}
                  key={index}
                >
                  <div className={styles.cardData}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{ height: "60px", width: "60px", margin: "0" }}
                    />
                    <h3>{e?.title}</h3>
                    <p
                      className={styles.cardDes}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                    <div className={styles.bannerBtnReferal}>
                      {strapiData?.buttons && (
                        <button
                          className={styles.Referalbtn}
                          onClick={() => handleModalShow(e?.title)}
                        >
                          Get Started
                        </button>
                      )}
                    </div>
                    <FormModal
                      showModal={showModal}
                      handleClose={handleModalClose}
                      modalData={modalData}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Banner
