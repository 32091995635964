import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/referalPage-sections/Banner"
import Faqs from "../components/common/Faqs2"
import Qualifies from "../components/referalPage-sections/Qualifies"
import Process from "../components/referalPage-sections/Process"
import Commission from "../components/referalPage-sections/Commission"

const referalprogram = ({ data }) => {
  const heroSection = data?.strapiPage?.sections[0]
  const qualifies = data?.strapiPage?.sections[1]
  const process = data?.strapiPage?.sections[2]
  const commission = data?.strapiPage?.sections[3]
  const faqs = data?.strapiPage.sections[4]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <Qualifies strapiData={qualifies} />
      <Process strapiData={process} />
      <Commission strapiData={commission} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "referal" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default referalprogram

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <title>{metaTitle}</title>
      <meta name="robots" content="noindex, follow"></meta>
      <meta name="googlebot" content="noindex, follow"></meta>
      <meta name="description" content={metaDescription} />
    </>
  )
}
