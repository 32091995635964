// extracted by mini-css-extract-plugin
export var Card = "Banner-module--Card--4c21d";
export var Referalbtn = "Banner-module--Referalbtn--d21f5";
export var bannerBtnReferal = "Banner-module--bannerBtnReferal--78181";
export var bannerHeading = "Banner-module--bannerHeading--d9cfa";
export var bannerReferalPage = "Banner-module--bannerReferalPage--23153";
export var cardData = "Banner-module--cardData--f7f0c";
export var cardDes = "Banner-module--cardDes--de31a";
export var cardWrapper = "Banner-module--cardWrapper--03daf";
export var referalSubtitle = "Banner-module--referalSubtitle--60e16";
export var referalSubtitle2 = "Banner-module--referalSubtitle2--8e3f7";