import React from "react"
import Modal from "react-bootstrap/Modal"
import ReferalPageForm from "./ReferalPageForm"
import * as styles from "./FormModal.module.scss"

const FormModal = ({ showModal, handleClose, modalData }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      size="md"
      keyboard={false}
      contentClassName={styles.modalContentReferalPage}
    >
      <Modal.Body className={styles.modalBodytesReferalPage}>
        <ReferalPageForm pageName={modalData} />
      </Modal.Body>
    </Modal>
  )
}

export default FormModal
